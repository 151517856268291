@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "react-spring-bottom-sheet/dist/style.css";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  font-family: 'Outfit', sans-serif;
}
html, body, #root, .Nina-App, .menu-screen{
  /*scroll-behavior: smooth;*/
  overscroll-behavior: none!important;
}

@media only screen and (min-width: 700px) {
  .menu-screen > div {
    max-width: 100vh;
  }

}



:root {
  --theme-primary-color: #007eb6;
  --theme-grey-color: #858992;
  --theme-orange-color: #0FC000;
  --theme-walls-indentation: 15px;
  --theme-shadow: 0 4px 12px #f1f4f9;
  --theme-fz-text: 12pt;
  --theme-fz-title-1: 18pt;
  --theme-fz-title-2: 16pt;
  --theme-modded-bg-color: #fff;
  --theme-modded-fore-color: #000;
  --theme-modded-sticky-bg: rgba(255, 255, 255, 0.8);
  --theme-modded-card-bg: #fcfeff;
}



.primary-color{
  color: var(--theme-primary-color);
}

.fore-color{
  color: var(--theme-modded-fore-color);
}
.card-modded-bg{
  background: var(--theme-modded-card-bg)
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes radiation {
  0%, 100% {
    box-shadow: 1px 0 19px 4px #eeedc7, inset 0 0 10px rgba(255, 255, 255, 0.5); }

  50% {
    box-shadow: 0 0 0 0 rgba(255, 245, 3, 0), inset 0 0 0 rgba(255, 255, 255, 0); }
}

.animate {
  animation : shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

.indent {
  padding-left: var(--theme-walls-indentation);
  padding-right: var(--theme-walls-indentation);
}
body{
  max-width: 100vh;
  margin: auto;
}
.Nina-App.mode-dark{
  background: var(--theme-modded-bg-color);
}

.no-scroll-screen{
  overflow: hidden;
  height: 100vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.ReactModal__Body--open {
  overflow-y: hidden;
}
body:fullscreen {
  overflow: scroll !important;
}
body:-ms-fullscreen {
  overflow: scroll !important;
}
body:-webkit-full-screen {
  overflow: scroll !important;
}
body:-moz-full-screen {
  overflow: scroll !important;
}

.swiper-button-next {
  background-image: url("./images/arrow_right_slider.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 55px;
  height: 55px;
}

.swiper-button-next::after {
  display: none;
}
.swiper-button-prev {
  background-image: url("./images/arrow_left_slider.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 55px;
  height: 55px;
}

.swiper-button-prev::after {
  display: none;
}


.screenTransition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.screenTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.screenTransition-exit {
  opacity: 1;
}
.screenTransition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.pincode-input-container{
  display: flex;
  justify-content: space-between;
  width: 75vw;
  padding: 5px 15px!important;
}
#otp-screen .pincode-input-container {
  width: 100%;
}
.pincode-input-container .input-style{
  width: 3rem !important;
  height: 3rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgb(0 0 0 / 30%);
  padding: 0;
}
[data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after{
  z-index: 4;
}

@media only screen and (min-width: 700px) {
  [data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after{
    max-width: 100vh;
  }
  [data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after {
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    margin-left: auto;
    margin-right: auto;
  }

}

.PhoneInputInput{
  border: solid thin black;
  border-radius: 10px;
  height: 65px;
  margin: 5px 0;
  padding-left: 10px;
  font-size: 20px;
}


