.splash-screen {
    width: 100vw;
    height: 100vh;
    background: var(--theme-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 700px) {

    .splash-screen {
        max-width: 100vh;
    }

}
